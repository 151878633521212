<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="filterData && filterData.length > 0 ? '355' : ''"
              :style="{maxHeight: !accountingCardReportList.data ? '525px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="filterData"
              item-class="py-4"
              fixed-header
              :items-per-page="limitData"
              :page.sync="page"
              :search="search"
              :loading="isProgressCircular"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              @update:sort-by="(val) => buildUrl(val, 'sortKey')"
              @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:[`item.accountNo`]="{ item }">
                <span v-if="!item.journalEntry" :class="!item.journalEntry ? 'font-weight-bold' : ''">{{ item.accountNo ? $t('accountingCardReport.showOpeningBalance') + "-" : null }}  {{ numberWithCommas(item.accountNo) }}</span>
                <span v-else>{{ item.accountNo }}</span>
              </template>
              <template v-slot:[`item.accountName`]="{ item }">
                <span :id="`row-${item.id}`">{{ accountNameHebrew.includes(item.accountName) && item.accountName ? $t(`accountingCardReport.${item.accountName}`) : item.accountName }}</span>
              </template>
              <template v-slot:[`item.documentDate`]="{ item }">
                <span>{{ item.documentDate && (profileById.date_format ? formatDateDDMMYYYY(new Date(item.documentDate).toISOString().substring(0,10)) :new Date(item.documentDate).toISOString().substring(0,10)) }}</span>
              </template>
              <template v-slot:[`item.valueDate`]="{ item }">
                <span>{{ item.valueDate && (profileById.date_format ? formatDateDDMMYYYY(new Date(item.valueDate).toISOString().substring(0,10)) :new Date(item.valueDate).toISOString().substring(0,10)) }}</span>
              </template>
              <template v-slot:[`item.details`]="{ item }">
                <span>{{ item.details ? $t(`accountingCardReport.${item.details.split("-")[0]}`) + '-' : null }}{{ item.details ? item.details.split("-")[1] + '-' : null }}{{ item.details ? item.details.split("-")[2] : null }}</span>
              </template>
              <template v-slot:[`item.debit`]="{ item }">
                <span style="direction:ltr" class="d-inline-block">{{ !item.debit ? null : numberWithCommas(item.debit) }}</span>
              </template>
              <template v-slot:[`item.credit`]="{ item }">
                <span style="direction:ltr" class="d-inline-block">{{ !item.credit ? null : numberWithCommas(item.credit) }}</span>
              </template>
              <template v-slot:[`item.balance`]="{ item }">
                <span v-if="item.journalEntry" style="direction:ltr" class="d-inline-block">{{ item.balance === '' ? '' : numberWithCommas(item.balance) }}</span>
              </template>
              <template
                v-slot:top
              >
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">{{$t('accountingCardReport.filters')}}</h6>
                  <v-spacer />
                  <HtmlToPdf class="me-2 mb-1" :disabled="!!(filterData && filterData.length > 0)" :model="accountingCardReportList" :payload="payload" type="AccountingCardReportPDF"/>
                  <ExcelAndResetFilterCommon :data="filterData.length > 0" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                <v-card-text>
                  <v-row :dense="true">
                    <v-col cols="5">
                      <v-row :dense="true">
                        <v-col cols="12" class="d-flex">
                          <v-menu
                            ref="invoiceStartDateMenu"
                            v-model="invoiceStartDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                dense
                                class="me-2"
                                v-model="formattedFromDate"
                                :label="$t('accountingCardReport.fromDate')"
                                @input="buildUrl"
                                readonly
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.startDate"
                              @input="buildUrl"
                              @change="$refs.invoiceStartDateMenu.save(model.startDate)"
                            >
                              <v-btn small class="primary" @click="$refs.invoiceStartDateMenu.save(getStartDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="invoiceEndDateMenu"
                            v-model="invoiceEndDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                dense
                                v-model="formattedToDate"
                                :label="$t('accountingCardReport.toDate')"
                                @input="buildUrl"
                                readonly
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.endDate"
                              @input="buildUrl"
                              @change="$refs.invoiceEndDateMenu.save(model.endDate)"
                            >
                              <v-btn small class="primary" @click="$refs.invoiceEndDateMenu.save(getEndDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="6">
                      <v-row :dense="true">
                        <v-col cols="6">
                          <v-combobox
                            dense
                            autocomplete="new-password"
                            v-model="selectedFromAccountNumber"
                            :items="accountingCardNumberList"
                            item-text="book_keeping_number"
                            item-value="id"
                            readonly
                            append-icon=""
                            @change="fromAccountCardNumberChange"
                            @click="openAccountingDialog('from')"
                            clearable
                            flat
                            hide-no-data
                            hide-details="auto"
                            :label="$t('accountingCardReport.fromAccountNumber')"
                          >
                            <template v-slot:selection="data">
                              <span>{{`${data.item.book_keeping_number}`}}</span>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="6">
                          <v-combobox
                            dense
                            autocomplete="new-password"
                            v-model="selectedToAccountNumber"
                            :items="accountingCardNumberList"
                            readonly
                            append-icon=""
                            item-text="book_keeping_number"
                            item-value="id"
                            @change="toAccountCardNumberChange"
                            @click="openAccountingDialog('to')"
                            clearable
                            flat
                            hide-no-data
                            hide-details="auto"
                            :label="$t('accountingCardReport.toAccountNumber')"
                          >
                            <template v-slot:selection="data">
                              <span>{{`${data.item.book_keeping_number}`}}</span>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="6">
                        </v-col>
                        <v-col cols="6">
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense class="my-2">
                    <v-col cols="4">
                      <strong>{{$t('accountingCardReport.groupBy')}}:</strong>
                      <v-radio-group v-model="row" row>
                        <v-radio :label="$t('accountingCardReport.accountNo')" color="primary" value="accountNo"></v-radio>
                        <v-radio :label="$t('accountingCardReport.journalEntry')" color="primary" value="journalEntry"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="5"></v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        hide-details="auto"
                        class="my-8"
                        height="30%"
                        dense
                        v-model="selectedGroup"
                        :items="groupValue"
                        item-text="name"
                        item-value="id"
                        clearable
                        flat
                        @input="buildUrl"
                        return-object
                        single-line
                        :label="$t('accountingCardReport.balanceSheetSection')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense class="my-2">
                    <v-col cols="12">
                      <v-checkbox
                        dense
                        v-model="show_opening_balance"
                        :label="$t('accountingCardReport.showOpeningBalance')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense class="my-2">
                    <v-col cols="12" class="text-center">
                      <ExecuteAndRestCommon :valid="valid" @reset="$store.commit('accountingReport/SET_ACCOUNTINGCARD_REPORT', [])" @paginate="paginate(false)" />
                    </v-col>
                  </v-row>
                </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">{{$t('accountingCardReport.accountingCardReport')}}</h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center"> {{$t('common.noDataFound')}}</v-card-text>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex mt-n6">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="accountingCardReportList.data ? pageCount : 0"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template slot="body.append">
                <tr v-if="selectedToAccountNumber && selectedFromAccountNumber && (selectedToAccountNumber.book_keeping_number === selectedFromAccountNumber.book_keeping_number)">
                  <td class="font-weight-bold text-end" colspan="8">
                    {{numberWithCommas(accountingCardReportList.difference)}}
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
            <v-divider />
            <v-row>
              <v-col cols="3">
                <span class="ps-3 pt-2 text-caption">{{ $t('common.numberOfTotalRows') }} {{accountingCardReportList && accountingCardReportList.totalRows ? numberOfRows : 0}}</span>
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col class="text-end font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                    <span class="pt-2 text-body-2 font-weight-bold">
                      {{$t('accountingCardReport.totalCreditAmount')}} {{accountingCardReportList && accountingCardReportList.totalCreditAmount ? numberWithCommas(accountingCardReportList.totalCreditAmount) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{$t('accountingCardReport.totalDebitAmount')}} {{accountingCardReportList && accountingCardReportList.totalDebitAmount ? numberWithCommas(accountingCardReportList.totalDebitAmount) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{$t('accountingCardReport.difference')}} {{accountingCardReportList && accountingCardReportList.difference ? numberWithCommas(accountingCardReportList.difference) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <AccountingCardDialog  @fromAccountCardNumberChange="fromAccountCardNumberChange" @toAccountCardNumberChange="toAccountCardNumberChange" :dialog.sync="accountingCardDialog" :typeAccount="typeAccount"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
import AccountingCardDialog from "@/components/moduleTableDialog/Dialog/AccountingCardDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import exportFromJSON from 'export-from-json';
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from '@/components/ExecuteAndRestCommon';
import { validationMixin } from '@/mixins/validationMixin';

export default {
  name: 'AccountingCardReport',
  components: {
    ListSkeleton,
    AccountingCardDialog,
    HtmlToPdf,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon
  },
  mixins: [validationMixin],
  data() {
    return {
      sortByValue: null,
      sortDescValue: null,
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      accessRights: [],
      search: "",
      listSkeleton: false,
      invoiceStartDateMenu: false,
      invoiceEndDateMenu: false,
      model: {
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null
      },
      accountingCardDialog: false,
      selectedToAccountNumber: null,
      selectedFromAccountNumber: null,
      typeAccount: "",
      valid: false,
      accountNameHebrew: [
        "Income","Expenses","Funds","Banks","Others","Employee","Customer","Supplier","Item","Bank","Checks","Cash","VAT","VATExemptedIncome","OverseasIncome","CustomerWithholdingTax","PurchasesVAT","SupplierWithholdingTax","EquipmentPurchaseVAT","ChequeFunds","CashFunds","IncomeVAT","Cheque", "ReceiptTaxtInvoice"
      ],
      filterData: [],
      row: "accountNo",
      selectedGroup: {},
      numberOfRows: "",
      show_opening_balance: true,
      isProgressCircular: false,
    }
  },
  computed: {
    groupValue() {
      return [
        {id: 1, name: this.$t('accountingCardReport.Employee')},
        {id: 2, name: this.$t('accountingCardReport.Customer')},
        {id: 3, name: this.$t('accountingCardReport.Supplier')},
        {id: 4, name: this.$t('accountingCardReport.Item')},
        {id: 5, name: this.$t('accountingCardReport.Income'), account_type: 0},
        {id: 6, name: this.$t('accountingCardReport.Expenses'), account_type: 1},
        {id: 7, name: this.$t('accountingCardReport.Funds'), account_type: 2},
        {id: 8, name: this.$t('accountingCardReport.Banks'), account_type: 4},
        {id: 9, name: this.$t('accountingCardReport.Others'), account_type: 3},
      ]
    },
    formattedFromDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.model.startDate) : parseDateYYYYMMDD(this.model.startDate);
      },
      set(val) {
        if (val.length == 10) {
          this.model.startDate = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      }
    },
    formattedToDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.model.endDate) : parseDateYYYYMMDD(this.model.endDate);
      },
      set(val) {
        if (val.length == 10) {
          this.model.endDate = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      }
    },
    params() {
      if(this.pagination) {
        return {
          ...this.pagination,
        };
      } else {
        return {}
      }
    },
    payload() {
      return {
        from_reference_one_date: this.model.startDate,
        to_reference_two_date: this.model.endDate,
        debit_account: this.selectedFromAccountNumber?.book_keeping_number,
        credit_account: this.selectedToAccountNumber?.book_keeping_number
      }
    },
    ...mapGetters({
      accountingCardReportList: 'accountingReport/accountingCardReportList',
      accountingCardNumberList: "accountingReport/accountingCardNumberList",
      locale: 'locale',
      accessRight: 'accessRight',
      profileById: 'profile/profileById',
      pageChange: "accountingCardPagination/pageChange",
      rowIndex: "accountingReport/rowIndex",
    }),
    headers() {
      return [
        { text: this.$t('accountingCardReport.accountNo'), align: "start", value: "accountNo", width: '180px', class: 'px-1', sortable: false },
        { text: this.$t('accountingCardReport.accountName'), value: "accountName", width: '140px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.documentDate'), value: "documentDate", width: '138px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.valueDate'), align: "left", value: "valueDate", width: '138px', sortable: false },
        { text: this.$t('accountingCardReport.details'), value: "details", width: '250px', sortable: false },
        { text: this.$t('accountingCardReport.journalEntry'), value: "journalEntry", width: '112px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.debit'), value: "debit", width: '100px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.credit'), value: "credit", width: '100px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.balance'), value: "balance", width: '100px', class: 'px-2', sortable: false },
      ]
    },
  },
  async created() {
    this.$store.dispatch("accountingReport/GetAccountCardNumber");
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  async mounted() {
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.$store.commit("accountingReport/SET_ACCOUNTINGCARD_REPORT_QUERY", this.$route.query);
    if(Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      const self = this;
      self.getNow();
    }
    await this.$store.dispatch('profile/GetCompanyById');
    let rowIndex = this.$store.state.accountingReport.rowIndex;
    if (rowIndex && this.accountingCardReportList?.data?.length > 0) {
      document.getElementById(`row-${rowIndex}`)?.scrollIntoView({ block: "center" })
    }
    this.listSkeleton = false;
  },
  beforeDestroy() {
    this.$store.commit("accountingCardPagination/SHOW_PAGE_CHANGE", {page: this.pagination?.page, limit: this.pagination?.itemsPerPage})
  },
  destroyed() {
    if(!this.$route.params.receipt_id || !this.$route.params.deposit_id || !this.$route.params.credit_invoice_id || !this.$route.params.invoice_id) {
      this.$store.commit('accountingReport/SET_ACCOUNTINGCARD_REPORT', [])
    }
  },
  watch: {
    accountingCardReportList: {
      handler() {
        let datttt = []
        let mainData = this.accountingCardReportList?.data?.map(o => o.accountCardData)
        mainData?.forEach(a => {
          a?.forEach(b => {
            datttt.push(b)
          })
          if (!this.selectedFromAccountNumber && !this.selectedToAccountNumber) {
            datttt.push({accountNo: "", accountName: "", documentDate: "", valueDate: "", details: "", journalEntry: "", debit: "", credit: "", balance: "", name: ""})
          }
        })
        this.filterData = datttt
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.model.startDate = this.$route.query.startDate || null
          this.model.endDate = this.$route.query.endDate || null
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    openAccountingDialog(type) {
      this.accountingCardDialog = true
      this.typeAccount = type
    },
    newPagination(val) {
      this.pagination = val
      this.numberOfRows = val.itemsLength
    },
    doubleClick(event, {item}) {
      if (item.details.split("-")[0] === "Invoice" || item.details.split("-")[0] === 'חשבונית') {
        const path = 'invoice/invoiceAction/' + item.reference_three;
        this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      } else if (item.details.split("-")[0] === "CreditInvoice" || item.details.split("-")[0] === 'חשבונית זיכוי') {
        const path = 'creditInvoice/creditInvoiceAction/' + item.reference_three;
        this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      } else if (item.details.split("-")[0] === "Deposit" || item.details.split("-")[0] === 'הפקדה') {
        const path = 'deposit/depositAction/' + item.reference_three;
        this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      } else if (item.details.split("-")[0] === "Receipt" || item.details.split("-")[0] === 'קבלה') {
        const path = 'receipt/editReceipt/' + item.reference_three;
        this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      }
      this.$store.commit('accountingReport/SET_ROWINDEX', item.reference_three);
    },
    getStartDate() {
      const today = new Date();
      this.model.startDate = today.toISOString().substring(0,10)
    },
    getEndDate() {
      const today = new Date();
      this.model.endDate = today.toISOString().substring(0,10)
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.search = null;
      this.selectedToAccountNumber = null;
      this.selectedFromAccountNumber = null;
      this.selectedGroup = null;
      this.show_opening_balance = true;
      this.row = 'accountNo';
      this.$store.commit("accountingReport/SET_ACCOUNTINGCARD_REPORT_QUERY", {});
      this.buildUrl();
    },
    downloadExcelFile() {
      let self = this;
      let res = [];
      self.accountingCardReportList.data.forEach((el) => {
        el.accountCardData.map((o) =>
          res.push(Object.fromEntries(
            self.headers
              .filter((ele) => ele.value !== "accountCardData.adjusted")
              .map((k) => [k.text, o[k.value]])
          ))
        );
      });
      if (this.locale === 'he') {
        res.forEach((el)=> { for (let x in el) {
          if (el[x].toString().split("-")[0] === 'Invoice') {
            el[x] = el[x].split("-")[2].trim() + ' - ' + el[x].split("-")[1].trim() + ' - ' + this.$t(`accountingCardReport.Invoice`)
          }
          if (el[x].toString().split("-")[0] === 'CreditInvoice') {
            el[x] = el[x].split("-")[2].trim() + ' - ' + el[x].split("-")[1].trim() + ' - ' + this.$t(`accountingCardReport.CreditInvoice`)
          }
          if (el[x].toString().split("-")[0] === 'Deposit') {
            el[x] = el[x].split("-")[2].trim() + ' - ' + el[x].split("-")[1].trim() + ' - ' + this.$t(`accountingCardReport.Deposit`)
          }
          if (el[x].toString().split("-")[0] === 'Receipt') {
            el[x] = el[x].split("-")[2].trim() + ' - ' + el[x].split("-")[1].trim() + ' - ' + this.$t(`accountingCardReport.Receipt`)
          }
        }})
      }
      exportFromJSON({data: res, fileName: "accountingCardReport", exportType: exportFromJSON.types.xls})
    },
    getNow() {
      const currentYear = new Date().getFullYear();
      const start_date = new Date(currentYear, 0, 1).getDate();
      const end_date = new Date(currentYear, 11, 31).getDate();
      const start_month = new Date(currentYear, 0, 1).getMonth() + 1;
      const end_month = new Date(currentYear, 11, 31).getMonth() + 1;
      const current_year = new Date().getUTCFullYear();
      this.model.startDate = current_year + "-" + 0 + start_month + "-" + 0 + start_date;
      this.model.endDate = current_year + "-" + end_month + "-" + end_date;
      this.buildUrl();
    },
    fromAccountCardNumberChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedFromAccountNumber = { id: item.id, book_keeping_number: item?.book_keeping_number };
        this.model.id = item.id;
      } else {
        this.selectedFromAccountNumber = null;
        this.model.id = null;
      }
    },
    toAccountCardNumberChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedToAccountNumber = { id: item.id, book_keeping_number: item?.book_keeping_number };
        this.model.id = item.id;
      } else {
        this.selectedToAccountNumber = null;
        this.model.id = null;
      }
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === 'string' || typeof val === 'object') {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.order_by = this.sortByValue;
      obj.startDate = this.model.startDate;
      obj.endDate = this.model.endDate;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === 'custId' && val === null) return acc;
        else if (key === "invoiceStartDate" && val === null) return acc;
        else if (key === "invoiceEndDate" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router.push({
        name: "AccountingCardReport",
        query: {
          ...obj,
        },
      }).catch(()=>{});
      this.$store.commit("accountingReport/SET_ACCOUNTINGCARD_REPORT_QUERY", obj);
    },
    async paginate() {
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      // filter with invoice date
      concateWhereAnd += this.model.startDate ? "from_reference_one_date," : "";
      concateWhereAnd += this.model.endDate ? "to_reference_two_date," : "";
      whereAndVal += this.model.startDate ? this.model.startDate + "," : "";
      whereAndVal += this.model.endDate ? this.model.endDate + "," : "";
      const payload = {
        where_or: this.selectedFromAccountNumber?.book_keeping_number ? "debit_account|" + this.selectedFromAccountNumber.book_keeping_number : undefined,
        groupBy: this.row === 'accountNo' ? 'accountNo' : 'journalEntry',
        type: this.selectedGroup?.name,
        openingbalance: this.show_opening_balance,
        whereType: this.selectedGroup?.account_type,
        whereOr: this.selectedToAccountNumber?.book_keeping_number ? "credit_account|" + this.selectedToAccountNumber.book_keeping_number : undefined,
        where_and:
          concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
            ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
            : undefined,
      }
      if (payload.whereType) {
          payload.type, payload.whereType = 'account_type,'+payload.whereType
        } else {
          if (payload.whereType == 0) {
            payload.type, payload.whereType = 'account_type,'+0
          }
        }

      this.isProgressCircular = true;
      // end make params for where_and
      await this.$store.dispatch("accountingReport/GetAccountingCardReport", payload).then(() => {
        this.isProgressCircular = false;
        this.$refs.search.focus();
      });
    }
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>